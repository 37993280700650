<div class="container">
  <div class="ui fixed inverted menu">
    <div class="ui container">
      <a class="header item">TAC Collector</a>
        <a class="item" routerLink="/pipelines" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">Pipelines</a>
        <a class="item" routerLink="/pigs" routerLinkActive="active">Pigs</a>
      
      <div class="right menu">
        <a class="item" href="https://newaccount1613263231154.freshdesk.com/support/home">Support</a>
      </div>
    </div>
  </div>
</div>
<router-outlet>

</router-outlet>



        <h4>Area Selection</h4>
        <div class="ui vertical menu">                
            <ng-container *ngFor="let area of objectKeys(runTree)" >
                <div class="item" (click)="selectArea(area,'%')" [ngClass]="{'active': selectedArea[0] == area}"><h3>{{area}}</h3></div>
                    <div class="menu">
                        <ng-container *ngFor="let run of runTree[area]">
                            <a class="item" (click)="selectArea(area,run)" [ngClass]="{'active': selectedArea[0] == area && selectedArea[1] == run}">
                                {{run}} 
                            </a>
                        </ng-container>
                    </div>
            </ng-container>
        </div>
        

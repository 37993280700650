import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PipelinesComponent } from './pipelines/pipelines.component';
import { PigsComponent } from './pigs/pigs.component';


const routes: Routes = [
  {path: '', component: PipelinesComponent},
  {path: 'pipelines', component: PipelinesComponent},
  {path: 'pigs', component: PigsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

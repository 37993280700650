import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataLookupService } from '../data-lookup.service';
import { CsvExport } from '../shared/csv-export';

interface pigRecord {
  lic_li_no: string,
  pig_type: string,
  inhibitor: number,
  meoh: number, 
  created: string,
  planned: string,
  sent: string,
  received: string,
  comments: string,
  id: number
}


@Component({
  selector: 'app-pigs',
  templateUrl: './pigs.component.html',
  styleUrls: ['./pigs.component.css']
})
export class PigsComponent implements OnInit {

  selectedArea = ['', ''];
  start = '';
  end = '';
  loading=false;
  pigData: pigRecord[];
  dateSort = 0;

  selectedID = 0;
  showConfirm = false;
  
  dateForm = new FormGroup({
    startDate: new FormControl('', [Validators.required]),
    endDate: new FormControl('', [Validators.required])
  });

  constructor(private dataLookup: DataLookupService) { }

  ngOnInit(): void {
    this.refreshData(this.start, this.end);
  }

  updateArea(event){
    this.selectedArea=event;
    this.refreshData(this.start, this.end);
  }

  onDateSort() {
    this.dateSort = this.dateSort + 1;
    if (this.dateSort > 2) {
      this.dateSort = 0;
    }
    if(this.dateSort==1){
      this.pigData.sort((a: any,b: any) =>  a.sent.localeCompare(b.sent) );
    }
    else if(this.dateSort == 2) {
      this.pigData.sort((a: any,b: any) =>  b.sent.localeCompare(a.sent) );
    }
    
  }
  refreshData(start, end) {
    this.dataLookup.getPigRecords(start, end).subscribe(response => {
      this.loading = false;
      console.log(response);
      this.pigData = response;
    });
  }
  export(data) {
    let csv = new CsvExport;
    csv.downloadFile(data, 'production_data.csv')
  }

  onSubmit(): void {
    var start = (this.dateForm.get('startDate').value);
    var end = (this.dateForm.get('endDate').value);
    this.start =start;
    this.end = end;
    this.refreshData(this.start, this.end);

    console.log(this.dateForm.get('startDate').value);
    console.log(this.dateForm.get('endDate').value);

  }
  receivePig(pigID, currentStatus) {
    if(currentStatus) {
      this.selectedID = pigID;
      this.showConfirm = true;
    }
  }
  dismissConfirm() {
    this.showConfirm = false;
    setTimeout(() => { this.refreshData(this.start, this.end) } ,410);
  }
}

<div class="ui main">
    <div class="ui stackable grid">
        <!--- Side menu for areas and runs-->
        <div class="three wide column"><app-nav-tree class="fullheight" (areaOutput)="updateArea($event)"> </app-nav-tree></div>
        <!-- Main Area for Meter Info-->
        <div class="twelve wide column centered">
            <div class="container">
                <div class="row" >
                    <button  (click)="export(pipelines)" class="ui button"><i class="download icon"></i>Export Data</button>
                    <table class="ui selectable celled table" >
                        <thead>
                        <tr>
                        <th>License & Line No</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Status</th>
                        <th>Diameter</th><th>Pig Status</th></tr></thead>
                        <tbody>

                        <tr *ngFor="let row of pipelines">
                            <td data-label="lic_li_no">{{row.lic_li_no}}</td>
                            <td data-label="From">{{row.fromLoc}}</td>
                            <td data-label="To">{{row.toLoc}}</td>
                            <td data-label="Status">{{row.status}}</td>
                            <td data-label="Diameter">{{row.diameter | number: '1.2-2'}}</td>
                            <td data-label="PigStatus">
                                <button class="ui icon button" (click)="launchPig(row.lic_li_no, row.sent)" [ngClass]="{'blue': row.sent}">
                                    S
                                </button>
                                <button class="ui icon button" (click)="receivePig(row.pigID, row.sent)" *ngIf="row.sent">
                                    R
                                </button>

                            </td>
                        </tr>

                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</div>
<app-pig-modal *ngIf="showPig" [licLiNo]="selectedLicLiNo" (dismiss)="dismissPig()"></app-pig-modal>
<app-confirm-modal *ngIf="showConfirm" [id]="selectedID" (dismiss)="dismissConfirm()"></app-confirm-modal>
<div (click)="onDismissClick()" class="ui dimmer visible active"> <!-- The dark background-->
    <div (click)="$event.stopPropagation()" class="ui modal visible active">

        <div class="header">
            <div class="items">
                <div class="item">
                    Create Pigging Record for {{licLiNo}} 
                    <button class="ui icon right floated button" (click)="onDismissClick()"> <i class="close icon"></i>  </button> 
                </div>
            </div>
            
        </div>
        <div class="content">
            <form class="ui form" [formGroup]="pigForm" (ngSubmit)="launchPig()">

                <div class="field" >
                    <label class="ui label" for="pigType">Pig Type</label>
                    <select class="ui dropdown" name="pigType" formControlName="pigType">
                        <option value="">Type</option>
                        <option *ngFor="let each of pigTypes"  value="{{each.type}}">{{each.type}}</option>

                      </select>
                </div>
                <div class="field">
                    <label class="ui label" for="areaName">Inhibitor</label>
                    <input class="input" name="inhibitor" formControlName="inhibitor"/>
                </div>
                <div class="field">
                    <label class="ui label" for="runName">Methanol</label>
                     <input class="input" name="meoh" formControlName="meoh"/>
                </div>
                <div class="field">
                    <label class="ui label" for="latitude">Comments</label>
                     <input class="input" name="latitude" formControlName="comments"/>
                </div>        

                  <button class="button ui secondary" [disabled]="pigForm.invalid">Send</button>

              </form>
        </div>

        <div class="actions">
            <ng-content select="[modalFooter]"></ng-content>
        </div>
    </div>
</div>

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PigModalComponent } from './pig-modal/pig-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { PigsComponent } from './pigs/pigs.component';
import { PipelinesComponent } from './pipelines/pipelines.component';

@NgModule({
  declarations: [
    AppComponent,
    PigModalComponent,
    ConfirmModalComponent,
    PigsComponent,
    PipelinesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    SharedModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface pipeline {
  lic_li_no: string,
  fromLoc: string,
  toLoc: string,
  status: string,
  diameter: number,
  sent: string,
  received: string,
  pigID: number
}
interface pigType {
  type: string
}

interface pigRecord {
  lic_li_no: string,
  pig_type: string,
  inhibitor: number,
  meoh: number, 
  created: string,
  planned: string,
  sent: string,
  received: string,
  comments: string,
  id: number
}

interface launchRecord {
  lic_li_no: string,
  pig_type: string,
  inhibitor: number,
  meoh: number,
  created: string,
  sent: string,
  comments: string
}

interface receiveRecord {
  id: number,
  received: string
}
interface area {
  area: string,
  run: string
}

@Injectable({
  providedIn: 'root'
})
export class DataLookupService {

  constructor(private httpClient: HttpClient) { }

  rootUrl = 'https://demo.tritac.ca/pig_data.php';

  getPipelines(area, run){
    return this.httpClient.get<pipeline[]>(this.rootUrl +'?function=pipelines&area='+area+'&run='+run);
  }

  getTypes(){
    return this.httpClient.get<pigType[]>(this.rootUrl +'?function=types');
  }

  getPigRecords(start: string, end: string){
    return this.httpClient.get<pigRecord[]>(this.rootUrl +'?function=pigs');
  }

  launchPig(launchRecord: launchRecord) {
    return this.httpClient.post(this.rootUrl, JSON.stringify(launchRecord));
  }

  receivePig(receiveRecord: receiveRecord) {
    return this.httpClient.post(this.rootUrl, JSON.stringify(receiveRecord));
  }

  getAreas() {
    return this.httpClient.get<area[]>(this.rootUrl + '?function=area');
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavTreeComponent } from './nav-tree/nav-tree.component';



@NgModule({
  declarations: [NavTreeComponent],
  imports: [
    CommonModule
  ],
  exports: [
    NavTreeComponent
  ]
})
export class SharedModule { }

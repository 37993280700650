<div class="ui main">
    <div class="ui stackable grid">
        <!--- Side menu for areas and runs-->
        <div class="three wide column"><app-nav-tree class="fullheight" (areaOutput)="updateArea($event)"> </app-nav-tree></div>
        <!-- Main Area for Meter Info-->
        <div class="twelve wide column centered">
            <div class="container">
                <div class="row ui form inline" [ngClass]="{'loading': loading}">
                    <form (ngSubmit)="onSubmit()" [formGroup]="dateForm">

                        <div class="three fields">
                            <div class="field">
                                <div class="ui calendar" id="example1">Start Date
                                    <div class="ui input left icon">
                                        <i class="calendar icon"></i>
                                        <input type="date" [formControl]="dateForm.get('startDate')" placeholder="2021-01-01">
                                    </div>
                                </div>
                            </div>
                            <div class="field">
                                <div class="ui calendar" id="example1">End Date
                                    <div class="ui input left icon">
                                        <i class="calendar icon"></i>
                                        <input type="date" [formControl]="dateForm.get('endDate')" placeholder="2021-01-01">
                                    </div>
                                </div>
                            </div>
                            <button class="ui basic submit button" [ngClass]="{'disabled': dateForm.errors}">Submit</button>
                        </div>
                        

                    </form>
                </div>
                <div class="row" >
                    <button  (click)="export(pigData)" class="ui button"><i class="download icon"></i>Export Data</button>
                    <table class="ui selectable celled table" >
                        <thead>
                        <tr>
                        <th>License & Line No</th>
                        <th>Sent Date
                            <button class="ui icon right floated button" (click)="onDateSort()"> <i class="icon sort" [ngClass]="{'up': dateSort == 1, 'down': dateSort == 2}"></i>  </button> </th>
                        <th>Received</th>
                        <th>Pig Type</th>
                        <th>Inhibitor</th><th>Methanol</th><th>Comments</th></tr></thead>
                        <tbody>

                        <tr *ngFor="let row of pigData">
                            <td data-label="lic_li_no">{{row.lic_li_no}}</td>
                            <td data-label="Sent">{{row.sent}}</td>
                            <td data-label="Received">
                                <button class="ui icon button" (click)="receivePig(row.id, row.sent)" *ngIf="!row.received">
                                    R
                                </button>
                                {{row.received}}</td>
                            <td data-label="Pig Type">{{row.pig_type}}</td>
                            <td data-label="Inhibitor">{{row.inhibitor | number: '1.2-2'}}</td>
                            <td data-label="Methanol">{{row.meoh | number: '1.2-2'}}</td>
                            <td data-label="Comments">{{row.comments}}</td>
                        </tr>

                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</div>
<app-confirm-modal *ngIf="showConfirm" [id]="selectedID" (dismiss)="dismissConfirm()"></app-confirm-modal>
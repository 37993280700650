import { Component, OnInit, ElementRef, Output, Input, EventEmitter } from '@angular/core';
import { DataLookupService } from '../data-lookup.service';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})

export class ConfirmModalComponent implements OnInit {

  @Output() dismiss = new EventEmitter();
  @Input() id: number;

  constructor(private dataLookup: DataLookupService, private el: ElementRef) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.el.nativeElement.remove();
  }

  onDismissClick() {
    this.dismiss.emit();
  }
  confirm(){
    let today = new Date().toISOString().slice(0, 10)
    this.dataLookup.receivePig({'id': this.id, 'received': today}).subscribe();
    this.dismiss.emit();
  }
}

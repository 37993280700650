import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DataLookupService } from '../../data-lookup.service';

@Component({
  selector: 'app-nav-tree',
  templateUrl: './nav-tree.component.html',
  styleUrls: ['./nav-tree.component.css']
})
export class NavTreeComponent implements OnInit {

  constructor(private dataLookup: DataLookupService) { }
  objectKeys = Object.keys;

  selectedArea = ['%','%']
  runTree = {};
  @Output() areaOutput: EventEmitter<string[]> = new EventEmitter();
  
  ngOnInit(): void {
    this.dataLookup.getAreas().subscribe(response => {
      response.forEach( value => {
        console.log(value);
        if(!this.runTree.hasOwnProperty(value.area) && value.area != null) {
          var temp = {};
          temp[value.area] = [value.run];
          //console.log(temp);
          this.runTree = {...this.runTree, ...temp}

          
          //Object.assign({}, this.runTree, temp);
        }
        else if(value.area != null && !this.runTree[value.area].includes(value.run)) {
          this.runTree[value.area].push(value.run);
        }
      });
    });
    //this.areaOutput.emit(this.selectedArea);
  }
  selectArea(area,run) {
    if(this.selectedArea[0] == area && this.selectedArea[1]==run) {
      this.selectedArea = ['%','%'];
    }
    else {
      this.selectedArea = [area,run]
    }
    this.areaOutput.emit(this.selectedArea);

  }

}

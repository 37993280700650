import { Component, OnInit } from '@angular/core';
import { DataLookupService } from '../data-lookup.service';
import { CsvExport } from '../shared/csv-export';

interface pipeline {
  lic_li_no: string,
  fromLoc: string,
  toLoc: string,
  status: string,
  diameter: number,
  sent: string,
  received: string,
  pigID: number
}


@Component({
  selector: 'app-pipelines',
  templateUrl: './pipelines.component.html',
  styleUrls: ['./pipelines.component.css']
})
export class PipelinesComponent implements OnInit {

  selectedArea = ['',''];
  pipelines: pipeline[];
  loading = false;

  showPig = false;
  showConfirm = false;
  selectedLicLiNo = 0;
  selectedID = 0;

  constructor(private dataLookup: DataLookupService) {}
  ngOnInit(): void {
    this.refreshData(this.selectedArea);
  }

  updateArea(event){
    this.selectedArea=event;
    this.refreshData(this.selectedArea);
  }

  
  refreshData(selectedArea) {
    this.dataLookup.getPipelines(selectedArea[0], selectedArea[1]).subscribe(response => {
      this.loading = false;
      console.log(response);
      this.pipelines = response;
    });
  }
  export(data) {
    let csv = new CsvExport;
    csv.downloadFile(data, 'production_data.csv')
  }

  launchPig(licLiNo, currentStatus) {
    if(!currentStatus){
      this.selectedLicLiNo = licLiNo;
      this.showPig = true;
    }
  }

  receivePig(pigID, currentStatus) {
    if(currentStatus) {
      this.selectedID = pigID;
      this.showConfirm = true;
    }
  }
  dismissConfirm() {
    this.showConfirm = false;
    setTimeout(() => { this.refreshData(this.selectedArea) } ,110);
  }
  dismissPig() {
    this.showPig = false;
    setTimeout(() => { this.refreshData(this.selectedArea) } ,110);
  }

}

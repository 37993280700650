<div (click)="onDismissClick()" class="ui dimmer visible active"> <!-- The dark background-->
    <div (click)="$event.stopPropagation()" class="ui modal visible active">

        <div class="header">
            <div class="items">
                <div class="item">
                    Receive Pig
                    <button class="ui icon right floated button" (click)="onDismissClick()"> <i class="close icon"></i>  </button> 
                </div>
            </div>
            
        </div>
        <div class="content center">
            <button class="button ui center floated secondary" (click)="confirm()">Receive</button>
        </div>

        <div class="actions">
            <ng-content select="[modalFooter]"></ng-content>
        </div>
    </div>
</div>

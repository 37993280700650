import { Component, OnInit, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Output, Input, EventEmitter } from '@angular/core'
import { DataLookupService } from '../data-lookup.service';

interface launchRecord {
  lic_li_no: string,
  pig_type: string,
  inhibitor: number,
  meoh: number,
  created: string,
  sent: string,
  comments: string
}

interface pigType {
  type: string
}

@Component({
  selector: 'app-pig-modal',
  templateUrl: './pig-modal.component.html',
  styleUrls: ['./pig-modal.component.css']
})
export class PigModalComponent implements OnInit {

  @Output() dismiss = new EventEmitter();
  @Input() licLiNo: string;

  pigTypes: pigType[];
  pigForm = new FormGroup({
    pigType: new FormControl('', [Validators.required]),
    inhibitor: new FormControl(0),
    meoh: new FormControl(0),
    comments: new FormControl('')
  });
  constructor(private dataLookup: DataLookupService, private el: ElementRef) { }

  ngOnInit(): void {
    this.dataLookup.getTypes().subscribe((response) => {
      this.pigTypes = response;
    });
  }

  ngOnDestroy(): void {
    this.el.nativeElement.remove();
  }

  onDismissClick() {
    this.dismiss.emit();
  }

  launchPig(){
    let today = new Date().toISOString().slice(0, 10)
    let launchRecord = {'lic_li_no': this.licLiNo, 'pig_type': this.pigForm.get('pigType').value, 'inhibitor': this.pigForm.get('inhibitor').value, 'meoh': this.pigForm.get('meoh').value, 'created': today, 'sent': today, 'comments': this.pigForm.get('comments').value};
    
    this.dataLookup.launchPig(launchRecord).subscribe();
    this.dismiss.emit();
  }
}

export class CsvExport {


    downloadFile(data, filename = 'data') {
      let arrHeader = Object.keys(data[0]);
      let csvData = this.ConvertToCSV(data, arrHeader);
      console.log(csvData)
      let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
      if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", filename + ".csv");
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    }
    ConvertToCSV(objArray, headerList) {
      console.log(objArray);
      console.log(headerList);
      let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      let str = '';
      let row = 'row,';
  
      let newHeaders = headerList;
  
      for (let index in newHeaders) {
        row += newHeaders[index] + ',';
      }
      row = row.slice(0, -1);
      str += row + '\r\n';
      for (let i = 0; i < array.length; i++) {
        let line = (i + 1) + '';
        for (let index in headerList) {
          let head = headerList[index];
  
          line += ',' + this.strRep(array[i][head]);
        }
        str += line + '\r\n';
      }
      return str;
    }

    strRep(data) {
      if(typeof data == "string") {
        let newData = data.replace(/,/g, " ");
         return newData;
      }
      else if(typeof data == "undefined") {
        return "-";
      }
      else if(typeof data == "number") {
        return  data.toString();
      }
      else {
        return data;
      }
    }
}
